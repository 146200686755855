import { NotificationConstants } from '../constants';

export const actionTypes = {
  SET_IS_SIDEBAR_OPEN: 'SET_IS_SIDEBAR_OPEN',
  SET_USER: 'SET_USER',
  SET_TOKEN: 'SET_TOKEN',
  SET_COMPANIES: 'SET_COMPANIES',
  UPDATE_COMPANIES: 'UPDATE_COMPANIES',
  SET_DEFAULT_COMPANY: 'SET_DEFAULT_COMPANY',
  SET_CURRENT_COMPANY: 'SET_CURRENT_COMPANY',
  SET_SEARCH_LIST: 'SET_SEARCH_LIST',
  UPDATE_SEARCH_LIST: 'UPDATE_SEARCH_LIST',
  UPDATE_CURRENT_COMPANY: 'UPDATE_CURRENT_COMPANY',
  SET_ALL_USER_ONLINE: 'SET_ALL_USER_ONLINE',
  UPDATE_ALL_USER_ONLINE: 'UPDATE_ALL_USER_ONLINE',
  SET_ALL_USER_TYPING_CHAT: 'SET_ALL_USER_TYPING_CHAT',
  UPDATE_ALL_USER_TYPING_CHAT: 'UPDATE_ALL_USER_TYPING_CHAT',
  SET_ALL_USER_TYPING_GROUP_CHAT: 'SET_ALL_USER_TYPING_GROUP_CHAT',
  UPDATE_ALL_USER_TYPING_GROUP_CHAT: 'UPDATE_ALL_USER_TYPING_GROUP_CHAT',
  SET_ALL_USER_TYPING_CARD: 'SET_ALL_USER_TYPING_CARD',
  UPDATE_ALL_USER_TYPING_CARD: 'UPDATE_ALL_USER_TYPING_CARD',
  SET_ALL_USER_TYPING_POST: 'SET_ALL_USER_TYPING_POST',
  UPDATE_ALL_USER_TYPING_POST: 'UPDATE_ALL_USER_TYPING_POST',
  SET_ALL_USER_TYPING_EVENT: 'SET_ALL_USER_TYPING_EVENT',
  UPDATE_ALL_USER_TYPING_EVENT: 'UPDATE_ALL_USER_TYPING_EVENT',
  SET_ALL_USER_TYPING_OCCURRENCE: 'SET_ALL_USER_TYPING_OCCURRENCE',
  UPDATE_ALL_USER_TYPING_OCCURRENCE: 'UPDATE_ALL_USER_TYPING_OCCURRENCE',
  SET_ALL_USER_TYPING_DOC: 'SET_ALL_USER_TYPING_DOC',
  UPDATE_ALL_USER_TYPING_DOC: 'UPDATE_ALL_USER_TYPING_DOC',
  SET_ALL_USER_TYPING_FILE: 'SET_ALL_USER_TYPING_FILE',
  UPDATE_ALL_USER_TYPING_FILE: 'UPDATE_ALL_USER_TYPING_FILE',
  SET_ALL_USER_TYPING_QUESTION: 'SET_ALL_USER_TYPING_QUESTION',
  UPDATE_ALL_USER_TYPING_QUESTION: 'UPDATE_ALL_USER_TYPING_QUESTION',
  SET_ALL_USER_TYPING_DISCUSSION: 'SET_ALL_USER_TYPING_DISCUSSION',
  UPDATE_ALL_USER_TYPING_DISCUSSION: 'UPDATE_ALL_USER_TYPING_DISCUSSION',
  SET_TEAMS: 'SET_TEAMS',
  SET_SORTED_TEAMS: 'SET_SORTED_TEAMS',
  UPDATE_TEAMS: 'UPDATE_TEAMS',
  SET_CURRENT_TEAM: 'SET_CURRENT_TEAM',
  SET_CURRENT_TEAM_BELOW_ROLE: 'SET_CURRENT_TEAM_BELOW_ROLE',
  UPDATE_CURRENT_TEAM: 'UPDATE_CURRENT_TEAM',
  UPDATE_CURRENT_TEAM_BELOW_ROLE: 'UPDATE_CURRENT_TEAM_BELOW_ROLE',
  SET_CURRENT_TEAM_MEMBERS: 'SET_CURRENT_TEAM_MEMBERS',
  SET_CURRENT_TEAM_BOARDS: 'SET_CURRENT_TEAM_BOARDS',
  SET_CURRENT_BOARD: 'SET_CURRENT_BOARD',
  SET_CURRENT_BOARD_LISTS: 'SET_CURRENT_BOARD_LISTS',
  SET_CURRENT_BOARD_LABELS: 'SET_CURRENT_BOARD_LABELS',
  UPDATE_CURRENT_BOARD_AND_LIST: 'UPDATE_CURRENT_BOARD_AND_LIST',
  UPDATE_CURRENT_BOARD: 'UPDATE_CURRENT_BOARD',
  UPDATE_CURRENT_BOARD_LISTS: 'UPDATE_CURRENT_BOARD_LISTS',
  UPDATE_CURRENT_LABELS: 'UPDATE_CURRENT_LABELS',
  UPDATE_FILTER_BOARD_LISTS: 'UPDATE_FILTER_BOARD_LISTS',
  SET_FILTERED_LISTS: 'SET_FILTERED_LISTS',
  SET_CURRENT_CARD: 'SET_CURRENT_CARD',
  UPDATE_CURRENT_CARD: 'UPDATE_CURRENT_CARD',
  SET_CURRENT_CARD_COMMENT: 'SET_CURRENT_CARD_COMMENT',
  SET_CURRENT_CARD_LIST: 'SET_CURRENT_CARD_LIST',
  UPDATE_CURRENT_CARD_LIST: 'UPDATE_CURRENT_CARD_LIST',
  SET_COLORS: 'SET_COLORS',
  SET_PREVIOUS_CHAT: 'SET_PREVIOUS_CHAT',
  SET_PREVIOUS_GROUP_CHAT: 'SET_PREVIOUS_GROUP_CHAT',
  SET_PREVIOUS_CHECK_IN: 'SET_PREVIOUS_CHECK_IN',
  SET_PREVIOUS_CHECK_IN_COMMENT: 'SET_PREVIOUS_CHECK_IN_COMMENT',
  SET_PREVIOUS_DISCUSSION_COMMENT: 'SET_PREVIOUS_DISCUSSION_COMMENT',
  SET_PREVIOUS_CARD_COMMENT: 'SET_PREVIOUS_CARD_COMMENT',
  SET_PREVIOUS_BLAST: 'SET_PREVIOUS_BLAST',
  SET_PREVIOUS_BLAST_COMMENT: 'SET_PREVIOUS_BLAST_COMMENT',
  SET_PREVIOUS_BOARD_LIST: 'SET_PREVIOUS_BOARD_LIST',
  SET_PREVIOUS_EVENT_COMMENT: 'SET_PREVIOUS_EVENT_COMMENT',
  UPDATE_PREVIOUS_BOARD_LIST: 'UPDATE_PREVIOUS_BOARD_LIST',
  SET_CURRENT_CHAT: 'SET_CURRENT_CHAT',
  UPDATE_CURRENT_CHAT: 'UPDATE_CURRENT_CHAT',
  SET_CURRENT_CHAT_UNREAD_MESSAGES_COUNTER: 'SET_CURRENT_CHAT_UNREAD_MESSAGES_COUNTER',
  SET_CURRENT_INVOICES_UNPAID_COUNTER: 'SET_CURRENT_INVOICES_UNPAID_COUNTER',
  SET_RECENT_CHATS: 'SET_RECENT_CHATS',
  UPDATE_RECENT_CHATS: 'UPDATE_RECENT_CHATS',
  SET_CURRENT_GROUP_CHAT: 'SET_CURRENT_GROUP_CHAT',
  UPDATE_CURRENT_GROUP_CHAT: 'UPDATE_CURRENT_GROUP_CHAT',
  SET_CURRENT_BLAST: 'SET_CURRENT_BLAST',
  SET_CURRENT_ROLE_USER: 'SET_CURRENT_ROLE_USER',
  SET_CURRENT_MY_TASK_CARD_LIST_ALL: 'SET_CURRENT_MY_TASK_CARD_LIST_ALL',
  SET_PREVIOUS_MY_TASK_CARD_LIST_ALL: 'SET_PREVIOUS_MY_TASK_CARD_LIST_ALL',
  SET_CURRENT_BOARD_CARD_LIST_ALL: 'SET_CURRENT_BOARD_CARD_LIST_ALL',
  SET_PREVIOUS_BOARD_CARD_LIST_ALL: 'SET_PREVIOUS_BOARD_CARD_LIST_ALL',
  SET_CURRENT_BOARD_CARD_CALENDAR_ALL: 'SET_CURRENT_BOARD_CARD_CALENDAR_ALL',
  SET_PREVIOUS_BOARD_CARD_CALENDAR_ALL: 'SET_PREVIOUS_BOARD_CARD_CALENDAR_ALL',
  SET_CURRENT_MY_TASK_CARD_CALENDAR_ALL: 'SET_CURRENT_MY_TASK_CARD_CALENDAR_ALL',
  SET_PREVIOUS_MY_TASK_CARD_CALENDAR_ALL: 'SET_PREVIOUS_MY_TASK_CARD_CALENDAR_ALL',
  SET_CURRENT_INVOICES: 'SET_CURRENT_INVOICES',
  SET_PREVIOUS_INVOICES: 'SET_PREVIOUS_INVOICES',
  SET_CURRENT_SUBSCRIPTIONS: 'SET_CURRENT_SUBSCRIPTIONS',
  SET_PREVIOUS_SUBSCRIPTIONS: 'SET_PREVIOUS_SUBSCRIPTIONS',
  SET_CURRENT_ROLE_SUPER_USERS: 'SET_CURRENT_ROLE_SUPER_USERS',
  SET_PREVIOUS_ROLE_SUPER_USERS: 'SET_PREVIOUS_ROLE_SUPER_USERS',
  SET_CURRENT_ROLE_USERS: 'SET_CURRENT_ROLE_USERS',
  SET_PREVIOUS_ROLE_USERS: 'SET_PREVIOUS_ROLE_USERS',
  SET_CURRENT_COUPONS: 'SET_CURRENT_COUPONS',
  SET_PREVIOUS_COUPONS: 'SET_PREVIOUS_COUPONS',
  SET_CURRENT_TEAM_FAVORITES: 'SET_CURRENT_TEAM_FAVORITES',
  SET_PREVIOUS_TEAM_FAVORITES: 'SET_PREVIOUS_TEAM_FAVORITES',
  SET_PREVIOUS_CHECKLISTS: 'SET_PREVIOUS_CHECKLISTS',
  UPDATE_CURRENT_CHECKLISTS: 'UPDATE_CURRENT_CHECKLISTS',
  SET_PREVIOUS_CHECKLIST_ITEMS: 'SET_PREVIOUS_CHECKLIST_ITEMS',
  UPDATE_CURRENT_CHECKLIST_ITEMS: 'UPDATE_CURRENT_CHECKLIST_ITEMS',
  UPDATE_CURRENT_BLAST: 'UPDATE_CURRENT_BLAST',
  UPDATE_CURRENT_INVOICES: 'UPDATE_CURRENT_INVOICES',
  UPDATE_CURRENT_MY_TASK_CARD_LIST_ALL: 'UPDATE_CURRENT_MY_TASK_CARD_LIST_ALL',
  UPDATE_CURRENT_MY_TASK_CARD_CALENDAR_ALL: 'UPDATE_CURRENT_MY_TASK_CARD_CALENDAR_ALL',
  UPDATE_CURRENT_BOARD_CARD_LIST_ALL: 'UPDATE_CURRENT_BOARD_CARD_LIST_ALL',
  UPDATE_CURRENT_BOARD_CARD_CALENDAR_ALL: 'UPDATE_CURRENT_BOARD_CARD_CALENDAR_ALL',
  UPDATE_CURRENT_SUBSCRIPTIONS: 'UPDATE_CURRENT_SUBSCRIPTIONS',
  UPDATE_CURRENT_ROLE_SUPER_USERS: 'UPDATE_CURRENT_ROLE_SUPER_USERS',
  UPDATE_CURRENT_ROLE_USERS: 'UPDATE_CURRENT_ROLE_USERS',
  UPDATE_CURRENT_COUPONS: 'UPDATE_CURRENT_COUPONS',
  UPDATE_CURRENT_TEAM_FAVORITES: 'UPDATE_CURRENT_TEAM_FAVORITES',
  SET_CURRENT_BLAST_POST: 'SET_CURRENT_BLAST_POST',
  UPDATE_CURRENT_BLAST_POST: 'UPDATE_CURRENT_BLAST_POST',
  SET_CURRENT_POST_COMMENT: 'SET_CURRENT_POST_COMMENT',
  SET_ALL_NOTIFICATIONS: 'SET_ALL_NOTIFICATIONS',
  SET_IS_FIRST_LOAD_ALL_NOTIFICATIONS: 'SET_IS_FIRST_LOAD_ALL_NOTIFICATIONS',
  SET_IS_FIRST_LOAD_NOTIFICATIONS: 'SET_IS_FIRST_LOAD_NOTIFICATIONS',
  UPDATE_TOTAL_ALL_NOTIFICATIONS: 'UPDATE_TOTAL_ALL_NOTIFICATIONS',
  UPDATE_TOTAL_ALL_NOTIFICATIONS_ALL: 'UPDATE_TOTAL_ALL_NOTIFICATIONS_ALL',
  UPDATE_ALL_NOTIFICATIONS: 'UPDATE_ALL_NOTIFICATIONS',
  SET_COUNTER_NOTIF: 'SET_COUNTER_NOTIF',
  SET_COUNTER_TASK_NOTIF: 'SET_COUNTER_TASK_NOTIF',
  SET_COUNTER_COMPANIES_NOTIF: 'SET_COUNTER_COMPANIES_NOTIF',
  UPDATE_COUNTER_COMPANIES_NOTIF: 'UPDATE_COUNTER_COMPANIES_NOTIF',
  SET_COUNTER_TEAMS_NOTIF: 'SET_COUNTER_TEAMS_NOTIF',
  UPDATE_COUNTER_TEAMS_NOTIF: 'UPDATE_COUNTER_TEAMS_NOTIF',
  SET_CHEERS_NOTIFICATIONS: 'SET_CHEERS_NOTIFICATIONS',
  UPDATE_TOTAL_CHEERS_NOTIFICATIONS: 'UPDATE_TOTAL_CHEERS_NOTIFICATIONS',
  UPDATE_CHEERS_NOTIFICATIONS: 'UPDATE_CHEERS_NOTIFICATIONS',
  SET_CURRENT_NOTIFICATIONS: 'SET_CURRENT_NOTIFICATIONS',
  RESET_CURRENT_NOTIFICATIONS: 'RESET_CURRENT_NOTIFICATIONS',
  RESET_ALL_NOTIFICATIONS: 'RESET_ALL_NOTIFICATIONS',
  UPDATE_TOTAL_NOTIFICATIONS: 'UPDATE_TOTAL_NOTIFICATIONS',
  UPDATE_CURRENT_NOTIFICATIONS: 'UPDATE_CURRENT_NOTIFICATIONS',
  SET_CHAT_NOTIFICATIONS: 'SET_CHAT_NOTIFICATIONS',
  SET_CURRENT_CHAT_NOTIFICATIONS: 'SET_CURRENT_CHAT_NOTIFICATIONS',
  SET_CURRENT_CHECK_IN: 'SET_CURRENT_CHECK_IN',
  UPDATE_CURRENT_CHECK_IN: 'UPDATE_CURRENT_CHECK_IN',
  SET_CURRENT_CHECK_IN_QUESTION: 'SET_CURRENT_CHECK_IN_QUESTION',
  UPDATE_CURRENT_CHECK_IN_QUESTION: 'UPDATE_CURRENT_CHECK_IN_QUESTION',
  SET_CURRENT_QUESTION_COMMENT: 'SET_CURRENT_QUESTION_COMMENT',
  SET_CURRENT_SCHEDULE: 'SET_CURRENT_SCHEDULE',
  SET_CURRENT_CHECKLISTS: 'SET_CURRENT_CHECKLISTS',
  SET_CURRENT_CHECKLIST_ITEMS: 'SET_CURRENT_CHECKLIST_ITEMS',
  UPDATE_CURRENT_SCHEDULE: 'UPDATE_CURRENT_SCHEDULE',
  SET_CURRENT_SCHEDULE_EVENT: 'SET_CURRENT_SCHEDULE_EVENT',
  UPDATE_CURRENT_SCHEDULE_EVENT: 'UPDATE_CURRENT_SCHEDULE_EVENT',
  SET_CURRENT_OCCURRENCE: 'SET_CURRENT_OCCURRENCE',
  UPDATE_CURRENT_OCCURRENCE: 'UPDATE_CURRENT_OCCURRENCE',
  SET_CURRENT_BUCKET_ITEMS: 'SET_CURRENT_BUCKET_ITEMS',
  UPDATE_CURRENT_BUCKET_ITEMS: 'UPDATE_CURRENT_BUCKET_ITEMS',
  SET_PREVIOUS_BUCKET_ITEMS: 'SET_PREVIOUS_BUCKET_ITEMS',
  SET_CURRENT_BUCKET: 'SET_CURRENT_BUCKET',
  UPDATE_CURRENT_BUCKET: 'UPDATE_CURRENT_BUCKET',
  SET_CURRENT_BUCKET_DOC: 'SET_CURRENT_BUCKET_DOC',
  UPDATE_CURRENT_BUCKET_DOC: 'UPDATE_CURRENT_BUCKET_DOC',
  SET_PREVIOUS_DOC_COMMENT: 'SET_PREVIOUS_DOC_COMMENT',
  SET_CURRENT_BUCKET_FILE: 'SET_CURRENT_BUCKET_FILE',
  UPDATE_CURRENT_BUCKET_FILE: 'UPDATE_CURRENT_BUCKET_FILE',
  SET_PREVIOUS_FILE_COMMENT: 'SET_PREVIOUS_FILE_COMMENT',
  SET_CURRENT_COMMENT: 'SET_CURRENT_COMMENT',
  UPDATE_CURRENT_COMMENT: 'UPDATE_CURRENT_COMMENT',
  SET_CURRENT_COMMENT_DISCUSSIONS: 'SET_CURRENT_COMMENT_DISCUSSIONS',
  UPDATE_CURRENT_COMMENT_DISCUSSIONS: 'UPDATE_CURRENT_COMMENT_DISCUSSIONS',
  SET_BUCKET_DOC_PATH: 'SET_BUCKET_DOC_PATH',
  SET_ERROR_RESPONSE: 'SET_ERROR_RESPONSE',
  SET_SHOW_PRIVATE_CHAT: 'SET_SHOW_PRIVATE_CHAT',
  SET_SHOW_NOTIFICATION: 'SET_SHOW_NOTIFICATION',
  SET_SHOW_ACTIVITY_MENU: 'SET_SHOW_ACTIVITY_MENU',
  SET_SHOW_COMPANIES_SELECT: 'SET_SHOW_COMPANIES_SELECT',
  SET_SHOW_USER_MENU: 'SET_SHOW_USER_MENU',
  SET_SHOW_EDIT_TEAM_MENU: 'SET_SHOW_EDIT_TEAM_MENU',
  SET_SHOW_EDIT_TEAM_FAVORITE_MENU: 'SET_SHOW_EDIT_TEAM_FAVORITE_MENU',
  SET_SELECTED_EDIT_TEAM: 'SET_SELECTED_EDIT_TEAM',
  SET_SELECTED_EDIT_TEAM_FAVORITE: 'SET_SELECTED_EDIT_TEAM_FAVORITE',
  SET_IS_DELAY_SHOW_ACTIVITY_MENU: 'SET_IS_DELAY_SHOW_ACTIVITY_MENU',
  SET_IS_DELAY_SHOW_NOTIF_MENU: 'SET_IS_DELAY_SHOW_NOTIF_MENU',
  SET_IS_DELAY_SHOW_COMPANIES_MENU: 'SET_IS_DELAY_SHOW_COMPANIES_MENU',
  SET_IS_DELAY_SHOW_USER_MENU: 'SET_IS_DELAY_SHOW_USER_MENU',
  SET_IS_DELAY_SHOW_EDIT_TEAM_MENU: 'SET_IS_DELAY_SHOW_EDIT_TEAM_MENU',
  SET_IS_DELAY_SHOW_EDIT_TEAM_FAVORITE_MENU: 'SET_IS_DELAY_SHOW_EDIT_TEAM_FAVORITE_MENU',
  SET_IS_OPEN_TEAM_FROM_FAVORITE: 'SET_IS_OPEN_TEAM_FROM_FAVORITE',
  SET_SHOULD_CHECK_TEAMS_COLOR: 'SET_SHOULD_CHECK_TEAMS_COLOR',
  SET_EDIT_CARD_ITEMS: 'SET_EDIT_CARD_ITEMS',
  SET_IS_FAVORITE_LIST_EMPTY: 'SET_IS_FAVORITE_LIST_EMPTY',
};

const Reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
        isAuthenticated: action.isAuthenticated,
      };
    case actionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case actionTypes.SET_IS_SIDEBAR_OPEN:
      return {
        ...state,
        isSidebarOpen: action.isSidebarOpen,
      };
    case actionTypes.SET_COMPANIES:
      return {
        ...state,
        currentCompanies: action.currentCompanies,
      };
    case actionTypes.UPDATE_COMPANIES:
      return {
        ...state,
        currentCompanies: action.updateCurrentCompanies(state.currentCompanies),
      };
    case actionTypes.SET_DEFAULT_COMPANY:
      return {
        ...state,
        defaultCompany: action.defaultCompany,
      };
    case actionTypes.SET_CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: action.currentCompany,
      };
    case actionTypes.UPDATE_CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: action.updateCurrentCompany(state.currentCompany),
      };
    case actionTypes.SET_ALL_USER_ONLINE:
      return {
        ...state,
        allUserOnline: action.allUserOnline,
      };
    case actionTypes.UPDATE_ALL_USER_ONLINE:
      return {
        ...state,
        allUserOnline: action.updateAllUserOnline(state.allUserOnline),
      };
    case actionTypes.SET_ALL_USER_TYPING_CHAT:
      return {
        ...state,
        allUserTypingChat: action.allUserTypingChat,
      };
    case actionTypes.UPDATE_ALL_USER_TYPING_CHAT:
      return {
        ...state,
        allUserTypingChat: action.updateAllUserTypingChat(state.allUserTypingChat),
      };
    case actionTypes.SET_ALL_USER_TYPING_GROUP_CHAT:
      return {
        ...state,
        allUserTypingGroupChat: action.allUserTypingGroupChat,
      };
    case actionTypes.UPDATE_ALL_USER_TYPING_GROUP_CHAT:
      return {
        ...state,
        allUserTypingGroupChat: action.updateAllUserTypingGroupChat(state.allUserTypingGroupChat),
      };
    case actionTypes.SET_ALL_USER_TYPING_CARD:
      return {
        ...state,
        allUserTypingCard: action.allUserTypingCard,
      };
    case actionTypes.UPDATE_ALL_USER_TYPING_CARD:
      return {
        ...state,
        allUserTypingCard: action.updateAllUserTypingCard(state.allUserTypingCard),
      };
    case actionTypes.SET_ALL_USER_TYPING_POST:
      return {
        ...state,
        allUserTypingPost: action.allUserTypingPost,
      };
    case actionTypes.UPDATE_ALL_USER_TYPING_POST:
      return {
        ...state,
        allUserTypingPost: action.updateAllUserTypingPost(state.allUserTypingPost),
      };
    case actionTypes.SET_ALL_USER_TYPING_DOC:
      return {
        ...state,
        allUserTypingDoc: action.allUserTypingDoc,
      };
    case actionTypes.UPDATE_ALL_USER_TYPING_DOC:
      return {
        ...state,
        allUserTypingDoc: action.updateAllUserTypingDoc(state.allUserTypingDoc),
      };
    case actionTypes.SET_ALL_USER_TYPING_FILE:
      return {
        ...state,
        allUserTypingFile: action.allUserTypingFile,
      };
    case actionTypes.UPDATE_ALL_USER_TYPING_FILE:
      return {
        ...state,
        allUserTypingFile: action.updateAllUserTypingFile(state.allUserTypingFile),
      };
    case actionTypes.SET_ALL_USER_TYPING_EVENT:
      return {
        ...state,
        allUserTypingEvent: action.allUserTypingEvent,
      };
    case actionTypes.UPDATE_ALL_USER_TYPING_EVENT:
      return {
        ...state,
        allUserTypingEvent: action.updateAllUserTypingEvent(state.allUserTypingEvent),
      };
    case actionTypes.SET_ALL_USER_TYPING_OCCURRENCE:
      return {
        ...state,
        allUserTypingOccurrence: action.allUserTypingOccurrence,
      };
    case actionTypes.UPDATE_ALL_USER_TYPING_OCCURRENCE:
      return {
        ...state,
        allUserTypingOccurrence: action.updateAllUserTypingOccurrence(
          state.allUserTypingOccurrence,
        ),
      };
    case actionTypes.SET_ALL_USER_TYPING_QUESTION:
      return {
        ...state,
        allUserTypingQuestion: action.allUserTypingQuestion,
      };
    case actionTypes.UPDATE_ALL_USER_TYPING_QUESTION:
      return {
        ...state,
        allUserTypingQuestion: action.updateAllUserTypingQuestion(state.allUserTypingQuestion),
      };
    case actionTypes.SET_ALL_USER_TYPING_DISCUSSION:
      return {
        ...state,
        allUserTypingDiscussion: action.allUserTypingDiscussion,
      };
    case actionTypes.UPDATE_ALL_USER_TYPING_DISCUSSION:
      return {
        ...state,
        allUserTypingDiscussion: action.updateAllUserTypingDiscussion(
          state.allUserTypingDiscussion,
        ),
      };
    case actionTypes.SET_TEAMS:
      return {
        ...state,
        teams: action.teams,
      };
    case actionTypes.SET_SORTED_TEAMS:
      return {
        ...state,
        sortedTeams: action.sortedTeams,
      };
    case actionTypes.UPDATE_TEAMS:
      return {
        ...state,
        teams: action.updateTeams(state.teams),
      };
    case actionTypes.SET_CURRENT_TEAM:
      return {
        ...state,
        currentTeam: action.currentTeam,
      };
    case actionTypes.SET_CURRENT_TEAM_BELOW_ROLE:
      return {
        ...state,
        currentTeamBelowRole: action.currentTeamBelowRole,
      };
    case actionTypes.UPDATE_CURRENT_TEAM:
      return {
        ...state,
        currentTeam: action.updateCurrentTeam(state.currentTeam),
      };
    case actionTypes.UPDATE_CURRENT_TEAM_BELOW_ROLE:
      return {
        ...state,
        currentTeam: action.updateCurrentTeamBelowRole(state.currentTeamBelowRole),
      };
    case actionTypes.SET_CURRENT_TEAM_MEMBERS:
      return {
        ...state,
        currentTeamMembers: action.currentTeamMembers,
      };
    case actionTypes.SET_CURRENT_TEAM_BOARDS:
      return {
        ...state,
        currentTeamBoards: action.currentTeamBoards,
      };
    case actionTypes.SET_CURRENT_BOARD:
      return {
        ...state,
        currentBoard: action.currentBoard,
      };
    case actionTypes.SET_CURRENT_BOARD_LISTS:
      return {
        ...state,
        currentBoardLists: action.currentBoardLists,
      };
    case actionTypes.SET_CURRENT_BOARD_LABELS:
      return {
        ...state,
        currentBoardLabels: action.currentBoardLabels,
      };
    case actionTypes.UPDATE_CURRENT_BOARD_AND_LIST: {
      const currentBoard = action.updateCurrentBoardAndList(
        state.currentBoardLists,
      );
      return {
        ...state,
        currentBoard,
        currentBoardLists: currentBoard?.lists,
      };
    }
    case actionTypes.UPDATE_CURRENT_BOARD: {
      const currentBoard = action.updateCurrentBoard(
        state.currentBoard,
      );
      return {
        ...state,
        currentBoard,
      };
    }
    case actionTypes.UPDATE_CURRENT_BOARD_LISTS: {
      const currentBoardLists = action.updateCurrentBoardLists(
        state.currentBoardLists,
      );
      return {
        ...state,
        currentBoardLists,
      };
    }
    case actionTypes.UPDATE_CURRENT_LABELS: {
      const currentBoardLabels = action.updateCurrentBoardLabels(
        state.currentBoardLabels,
      );
      return {
        ...state,
        currentBoardLabels,
      };
    }
    case actionTypes.UPDATE_FILTER_BOARD_LISTS: {
      const filteredLists = action.updateFilteredBoardLists(
        state.filteredLists,
      );
      return {
        ...state,
        filteredLists,
      };
    }
    case actionTypes.UPDATE_PREVIOUS_BOARD_LIST: {
      const previousBoardList = action.updatePreviousBoardList(
        state.currentBoardLists,
        state.previousBoardList,
      );
      return {
        ...state,
        previousBoardList,
      };
    }
    case actionTypes.SET_FILTERED_LISTS:
      return {
        ...state,
        filteredLists: action.filteredLists,
      };
    case actionTypes.SET_CURRENT_CARD:
      return {
        ...state,
        currentCard: action.currentCard,
      };
    case actionTypes.UPDATE_CURRENT_CARD:
      return {
        ...state,
        currentCard: action.updateCurrentCard(state.currentCard),
      };
    case actionTypes.SET_CURRENT_CARD_LIST:
      return {
        ...state,
        currentCardList: action.currentCardList,
      };
    case actionTypes.UPDATE_CURRENT_CARD_LIST:
      return {
        ...state,
        currentCardList: action.updateCurrentCardList(state.currentCardList),
      };
    case actionTypes.SET_COLORS:
      return {
        ...state,
        colors: action.colors,
      };
    case actionTypes.SET_PREVIOUS_CHAT:
      return {
        ...state,
        previousChat: action.previousChat,
      };
    case actionTypes.SET_PREVIOUS_GROUP_CHAT:
      return {
        ...state,
        previousGroupChat: action.previousGroupChat,
      };
    case actionTypes.SET_PREVIOUS_CHECK_IN:
      return {
        ...state,
        previousCheckIn: action.previousCheckIn,
      };
    case actionTypes.SET_PREVIOUS_MY_TASK_CARD_LIST_ALL:
      return {
        ...state,
        previousMyTaskCardListAll: action.previousMyTaskCardListAll,
      };
    case actionTypes.SET_PREVIOUS_BOARD_CARD_LIST_ALL:
      return {
        ...state,
        previousBoardCardListAll: action.previousBoardCardListAll,
      };
    case actionTypes.SET_PREVIOUS_BOARD_CARD_CALENDAR_ALL:
      return {
        ...state,
        previousBoardCardCalendarAll: action.previousBoardCardCalendarAll,
      };
    case actionTypes.SET_PREVIOUS_MY_TASK_CARD_CALENDAR_ALL:
      return {
        ...state,
        previousMyTaskCardCalendarAll: action.previousMyTaskCardCalendarAll,
      };
    case actionTypes.SET_PREVIOUS_INVOICES:
      return {
        ...state,
        previousInvoices: action.previousInvoices,
      };
    case actionTypes.SET_PREVIOUS_BUCKET_ITEMS:
      return {
        ...state,
        previousBucketItems: action.previousBucketItems,
      };
    case actionTypes.SET_PREVIOUS_SUBSCRIPTIONS:
      return {
        ...state,
        previousSubscriptions: action.previousSubscriptions,
      };
    case actionTypes.SET_PREVIOUS_ROLE_SUPER_USERS:
      return {
        ...state,
        previousRoleSuperUsers: action.previousRoleSuperUsers,
      };
    case actionTypes.SET_PREVIOUS_ROLE_USERS:
      return {
        ...state,
        previousRoleUsers: action.previousRoleUsers,
      };
    case actionTypes.SET_PREVIOUS_COUPONS:
      return {
        ...state,
        previousCoupons: action.previousCoupons,
      };
    case actionTypes.SET_PREVIOUS_TEAM_FAVORITES:
      return {
        ...state,
        previousTeamFavorites: action.previousTeamFavorites,
      };
    case actionTypes.SET_PREVIOUS_CHECKLISTS:
      return {
        ...state,
        previousChecklists: action.previousChecklists,
      };
    case actionTypes.SET_PREVIOUS_CHECKLIST_ITEMS:
      return {
        ...state,
        previousChecklistItems: action.previousChecklistItems,
      };
    case actionTypes.SET_PREVIOUS_BLAST_COMMENT:
      return {
        ...state,
        previousBlastComment: action.previousBlastComment,
      };
    case actionTypes.SET_PREVIOUS_CHECK_IN_COMMENT:
      return {
        ...state,
        previousCheckInComment: action.previousCheckInComment,
      };
    case actionTypes.SET_PREVIOUS_DISCUSSION_COMMENT:
      return {
        ...state,
        previousDiscussionComment: action.previousDiscussionComment,
      };
    case actionTypes.SET_PREVIOUS_CARD_COMMENT:
      return {
        ...state,
        previousCardComment: action.previousCardComment,
      };
    case actionTypes.SET_PREVIOUS_BLAST:
      return {
        ...state,
        previousBlast: action.previousBlast,
      };
    case actionTypes.SET_PREVIOUS_BOARD_LIST:
      return {
        ...state,
        previousBoardList: action.previousBoardList,
      };
    case actionTypes.SET_PREVIOUS_EVENT_COMMENT:
      return {
        ...state,
        previousEventComment: action.previousEventComment,
      };
    case actionTypes.SET_CURRENT_CHAT:
      return {
        ...state,
        currentChat: action.currentChat,
      };
    case actionTypes.UPDATE_CURRENT_CHAT:
      return {
        ...state,
        currentChat: action.updateCurrentChat(state.currentChat),
      };
    case actionTypes.SET_CURRENT_CHAT_UNREAD_MESSAGES_COUNTER:
      return {
        ...state,
        currentChatUnreadMessagesCounter: action.currentChatUnreadMessagesCounter,
      };
    case actionTypes.SET_CURRENT_INVOICES_UNPAID_COUNTER:
      return {
        ...state,
        currentInvoicesUnpaidCounter: action.currentInvoicesUnpaidCounter,
      };
    case actionTypes.SET_RECENT_CHATS:
      return {
        ...state,
        recentChats: action.recentChats,
      };
    case actionTypes.UPDATE_RECENT_CHATS:
      return {
        ...state,
        recentChats: action.updateRecentChats(state.recentChats),
      };
    case actionTypes.SET_CURRENT_GROUP_CHAT:
      return {
        ...state,
        currentGroupChat: action.currentGroupChat,
      };
    case actionTypes.UPDATE_CURRENT_GROUP_CHAT:
      return {
        ...state,
        currentGroupChat: action.updateCurrentGroupChat(state.currentGroupChat),
      };
    case actionTypes.SET_CURRENT_BLAST:
      return {
        ...state,
        currentBlast: action.currentBlast,
      };
    case actionTypes.SET_CURRENT_ROLE_USER:
      return {
        ...state,
        currentRoleUser: action.currentRoleUser,
      };
    case actionTypes.UPDATE_CURRENT_BLAST:
      return {
        ...state,
        currentBlast: action.updateBlast(state.currentBlast),
      };
    case actionTypes.SET_CURRENT_BLAST_POST:
      return {
        ...state,
        currentBlastPost: action.currentBlastPost,
      };
    case actionTypes.UPDATE_CURRENT_BLAST_POST:
      return {
        ...state,
        currentBlastPost: action.updateCurrentBlastPost(state.currentBlastPost),
      };
    case actionTypes.SET_CURRENT_POST_COMMENT:
      return {
        ...state,
        currentBlastPost: {
          ...state.currentBlastPost,
          comments: action.currentPostComment,
        },
      };
    case actionTypes.SET_CURRENT_CARD_COMMENT:
      return {
        ...state,
        currentCard: {
          ...state.currentCard,
          comments: action.currentCardComment,
        },
      };
    case actionTypes.SET_CURRENT_BUCKET_ITEMS:
      return {
        ...state,
        currentBucketItems: action.currentBucketItems,
      };
    case actionTypes.SET_CURRENT_MY_TASK_CARD_LIST_ALL:
      return {
        ...state,
        currentMyTaskCardListAll: action.currentMyTaskCardListAll,
      };
    case actionTypes.SET_CURRENT_BOARD_CARD_LIST_ALL:
      return {
        ...state,
        currentBoardCardListAll: action.currentBoardCardListAll,
      };
    case actionTypes.SET_CURRENT_BOARD_CARD_CALENDAR_ALL:
      return {
        ...state,
        currentBoardCardCalendarAll: action.currentBoardCardCalendarAll,
      };
    case actionTypes.SET_CURRENT_MY_TASK_CARD_CALENDAR_ALL:
      return {
        ...state,
        currentMyTaskCardCalendarAll: action.currentMyTaskCardCalendarAll,
      };
    case actionTypes.SET_CURRENT_INVOICES:
      return {
        ...state,
        currentInvoices: action.currentInvoices,
      };
    case actionTypes.SET_CURRENT_SUBSCRIPTIONS:
      return {
        ...state,
        currentSubscriptions: action.currentSubscriptions,
      };
    case actionTypes.SET_CURRENT_ROLE_SUPER_USERS:
      return {
        ...state,
        currentRoleSuperUsers: action.currentRoleSuperUsers,
      };
    case actionTypes.SET_CURRENT_ROLE_USERS:
      return {
        ...state,
        currentRoleUsers: action.currentRoleUsers,
      };
    case actionTypes.SET_CURRENT_COUPONS:
      return {
        ...state,
        currentCoupons: action.currentCoupons,
      };
    case actionTypes.SET_CURRENT_TEAM_FAVORITES:
      return {
        ...state,
        currentTeamFavorites: action.currentTeamFavorites,
      };
    case actionTypes.SET_CURRENT_CHECKLISTS:
      return {
        ...state,
        currentChecklists: action.currentChecklists,
      };
    case actionTypes.SET_CURRENT_CHECKLIST_ITEMS:
      return {
        ...state,
        currentChecklistItems: action.currentChecklistItems,
      };
    case actionTypes.UPDATE_CURRENT_BUCKET_ITEMS:
      return {
        ...state,
        currentBucketItems: action.updateBucketItems(state.currentBucketItems),
      };
    case actionTypes.UPDATE_CURRENT_INVOICES:
      return {
        ...state,
        currentInvoices: action.updateInvoices(state.currentInvoices),
      };
    case actionTypes.UPDATE_CURRENT_MY_TASK_CARD_LIST_ALL:
      return {
        ...state,
        currentMyTaskCardListAll:
        action.updateCurrentMyTaskCardListAll(state.currentMyTaskCardListAll),
      };
    case actionTypes.UPDATE_CURRENT_BOARD_CARD_LIST_ALL:
      return {
        ...state,
        currentBoardCardListAll:
        action.updateCurrentBoardCardListAll(state.currentBoardCardListAll),
      };
    case actionTypes.UPDATE_CURRENT_BOARD_CARD_CALENDAR_ALL:
      return {
        ...state,
        currentBoardCardCalendarAll:
        action.updateCurrentBoardCardCalendarAll(state.currentBoardCardCalendarAll),
      };
    case actionTypes.UPDATE_CURRENT_MY_TASK_CARD_CALENDAR_ALL:
      return {
        ...state,
        currentMyTaskCardCalendarAll:
        action.updateCurrentMyTaskCardCalendarAll(state.currentMyTaskCardCalendarAll),
      };
    case actionTypes.UPDATE_CURRENT_SUBSCRIPTIONS:
      return {
        ...state,
        currentSubscriptions: action.updateSubscriptions(state.currentSubscriptions),
      };
    case actionTypes.UPDATE_CURRENT_ROLE_SUPER_USERS:
      return {
        ...state,
        currentRoleSuperUsers: action.updateRoleSuperUsers(state.currentRoleSuperUsers),
      };
    case actionTypes.UPDATE_CURRENT_ROLE_USERS:
      return {
        ...state,
        currentRoleUsers: action.updateRoleUsers(state.currentRoleUsers),
      };
    case actionTypes.UPDATE_CURRENT_COUPONS:
      return {
        ...state,
        currentCoupons: action.updateCoupons(state.currentCoupons),
      };
    case actionTypes.UPDATE_CURRENT_TEAM_FAVORITES:
      return {
        ...state,
        currentTeamFavorites: action.updateTeamFavorites(state.currentTeamFavorites),
      };
    case actionTypes.UPDATE_CURRENT_CHECKLISTS:
      return {
        ...state,
        currentChecklists: action.updateChecklists(state.currentChecklists),
      };
    case actionTypes.UPDATE_CURRENT_CHECKLIST_ITEMS:
      return {
        ...state,
        currentChecklistItems: action.updateChecklistItems(state.currentChecklistItems),
      };
    case actionTypes.SET_ALL_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: {
          ...state.allNotifications,
          data: action.data,
        },
      };
    case actionTypes.SET_IS_FIRST_LOAD_ALL_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: {
          ...state.allNotifications,
          isFirstLoad: action.isFirstLoad
        }
      }
      
    case actionTypes.UPDATE_TOTAL_ALL_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: {
          ...state.allNotifications,
          total: action.total,
        },
      };
    case actionTypes.UPDATE_TOTAL_ALL_NOTIFICATIONS_ALL:
      return {
        ...state,
        allNotifications: {
          ...state.allNotifications,
          totalAll: action.totalAll,
        },
      };
    case actionTypes.UPDATE_ALL_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: action.updateAllNotification(state.allNotifications),
      };
    case actionTypes.SET_CHEERS_NOTIFICATIONS:
      return {
        ...state,
        cheersNotifications: {
          ...state.cheersNotifications,
          data: action.data,
        },
      };
    case actionTypes.UPDATE_TOTAL_CHEERS_NOTIFICATIONS:
      return {
        ...state,
        cheersNotifications: {
          ...state.cheersNotifications,
          total: action.total,
        },
      };
    case actionTypes.UPDATE_CHEERS_NOTIFICATIONS:
      return {
        ...state,
        cheersNotifications: action.updateCheersNotification(state.cheersNotifications),
      };
    case actionTypes.SET_COUNTER_NOTIF:
      return {
        ...state,
        counterNotif: {
          chat: action.chat,
          nonChat: action.nonChat,
        },
      };
    case actionTypes.SET_COUNTER_TASK_NOTIF:
      return {
        ...state,
        counterTaskNotif: {
          totalCounter: action.totalCounter,
        },
      };
    case actionTypes.SET_COUNTER_COMPANIES_NOTIF:
      return {
        ...state,
        counterCompaniesNotif: {
          totalCounter: action.totalCounter,
          companies: action.companies,
        },
      };
    case actionTypes.UPDATE_COUNTER_COMPANIES_NOTIF:
      return {
        ...state,
        counterCompaniesNotif: action.updateCounterCompaniesNotif(state.counterCompaniesNotif),
      };
    case actionTypes.SET_COUNTER_TEAMS_NOTIF:
      return {
        ...state,
        counterTeamsNotif: action.counterTeamsNotif,
      };
    case actionTypes.UPDATE_COUNTER_TEAMS_NOTIF:
      return {
        ...state,
        counterTeamsNotif: action.updateCounterTeamsNotif(state.counterTeamsNotif),
      };
    case actionTypes.SET_CURRENT_NOTIFICATIONS:
      return {
        ...state,
        currentNotifications: {
          ...state.currentNotifications,
          data: action.data,
        },
      };
    case actionTypes.SET_IS_FIRST_LOAD_NOTIFICATIONS:
      return {
        ...state,
        currentNotifications: {
          ...state.currentNotifications,
          isFirstLoad: action.isFirstLoad,
        }
      }
    case actionTypes.RESET_CURRENT_NOTIFICATIONS:
      return {
        ...state,
        currentNotifications: {
          total: NotificationConstants.limitNotif,
          data: [],
          isFirstLoad: true,
        },
      };
    case actionTypes.RESET_ALL_NOTIFICATIONS:
      return {
        ...state,
        allNotifications: {
          total: NotificationConstants.limitNotif,
          data: [],
          isFirstLoad: true,
        },
      };
    case actionTypes.UPDATE_TOTAL_NOTIFICATIONS:
      return {
        ...state,
        currentNotifications: {
          ...state.currentNotifications,
          total: action.total,
        },
      };
    case actionTypes.UPDATE_CURRENT_NOTIFICATIONS:
      return {
        ...state,
        currentNotifications: action.updateNotification(state.currentNotifications),
      };
    case actionTypes.SET_CHAT_NOTIFICATIONS:
      return {
        ...state,
        chatNotifications: action.chatNotifications,
      };
    case actionTypes.SET_CURRENT_CHAT_NOTIFICATIONS:
      return {
        ...state,
        currentChatNotifications: action.currentChatNotifications,
      };
    case actionTypes.SET_CURRENT_CHECK_IN:
      return {
        ...state,
        currentCheckIn: action.currentCheckIn,
      };
    case actionTypes.UPDATE_CURRENT_CHECK_IN:
      return {
        ...state,
        currentCheckIn: action.updateCheckIn(state.currentCheckIn),
      };
    case actionTypes.SET_CURRENT_CHECK_IN_QUESTION:
      return {
        ...state,
        currentCheckInQuestion: action.currentCheckInQuestion,
      };
    case actionTypes.UPDATE_CURRENT_CHECK_IN_QUESTION:
      return {
        ...state,
        currentCheckInQuestion: action.updateCheckInQuestion(state.currentCheckInQuestion),
      };
    case actionTypes.SET_CURRENT_QUESTION_COMMENT:
      return {
        ...state,
        currentCheckInQuestion: {
          ...state.currentCheckInQuestion,
          comments: action.currentQuestionComment,
        },
      };
    case actionTypes.SET_CURRENT_SCHEDULE:
      return {
        ...state,
        currentSchedule: action.currentSchedule,
      };
    case actionTypes.UPDATE_CURRENT_SCHEDULE:
      return {
        ...state,
        currentSchedule: action.updateSchedule(state.currentSchedule),
      };
    case actionTypes.SET_CURRENT_SCHEDULE_EVENT:
      return {
        ...state,
        currentScheduleEvent: action.currentScheduleEvent,
      };
    case actionTypes.UPDATE_CURRENT_SCHEDULE_EVENT:
      return {
        ...state,
        currentScheduleEvent: action.updateScheduleEvent(state.currentScheduleEvent),
      };
    case actionTypes.SET_CURRENT_OCCURRENCE:
      return {
        ...state,
        currentOccurrence: action.currentOccurrence,
      };
    case actionTypes.UPDATE_CURRENT_OCCURRENCE:
      return {
        ...state,
        currentOccurrence: action.updateOccurrence(state.currentOccurrence),
      };
    case actionTypes.SET_CURRENT_BUCKET:
      return {
        ...state,
        currentBucket: action.currentBucket,
      };
    case actionTypes.UPDATE_CURRENT_BUCKET:
      return {
        ...state,
        currentBucket: action.updateBucket(state.currentBucket),
      };
    case actionTypes.SET_CURRENT_BUCKET_DOC:
      return {
        ...state,
        currentBucketDoc: action.currentBucketDoc,
      };
    case actionTypes.UPDATE_CURRENT_BUCKET_DOC:
      return {
        ...state,
        currentBucketDoc: action.updateBucketDoc(state.currentBucketDoc),
      };
    case actionTypes.SET_SEARCH_LIST:
      return {
        ...state,
        searchList: action.searchList,
      };
    case actionTypes.UPDATE_SEARCH_LIST:
      return {
        ...state,
        searchList: action.updateSearchList(state.searchList),
      };
    case actionTypes.SET_PREVIOUS_DOC_COMMENT:
      return {
        ...state,
        previousDocComment: action.previousDocComment,
      };
    case actionTypes.SET_CURRENT_BUCKET_FILE:
      return {
        ...state,
        currentBucketFile: action.currentBucketFile,
      };
    case actionTypes.UPDATE_CURRENT_BUCKET_FILE:
      return {
        ...state,
        currentBucketFile: action.updateBucketFile(state.currentBucketFile),
      };
    case actionTypes.SET_PREVIOUS_FILE_COMMENT:
      return {
        ...state,
        previousFileComment: action.previousFileComment,
      };
    case actionTypes.SET_CURRENT_COMMENT:
      return {
        ...state,
        currentComment: action.currentComment,
      };
    case actionTypes.UPDATE_CURRENT_COMMENT:
      return {
        ...state,
        currentComment: action.updateComment(state.currentComment),
      };
    case actionTypes.SET_CURRENT_COMMENT_DISCUSSIONS:
      return {
        ...state,
        currentCommentDiscussions: action.currentCommentDiscussions,
      };
    case actionTypes.UPDATE_CURRENT_COMMENT_DISCUSSIONS:
      return {
        ...state,
        currentCommentDiscussions: action.updateDiscussionComment(state.currentCommentDiscussions),
      };
    case actionTypes.SET_BUCKET_DOC_PATH:
      return {
        ...state,
        bucketDocPath: action.bucketDocPath,
      };
    case actionTypes.SET_ERROR_RESPONSE:
      return {
        ...state,
        errorResponse: action.errorResponse,
      };
    case actionTypes.SET_SHOW_PRIVATE_CHAT:
      return {
        ...state,
        showPrivateChat: action.showPrivateChat,
      };
    case actionTypes.SET_SHOW_NOTIFICATION:
      return {
        ...state,
        showNotification: action.showNotification,
      };
    case actionTypes.SET_SHOW_ACTIVITY_MENU:
      return {
        ...state,
        showActivityMenu: action.showActivityMenu,
      };
    case actionTypes.SET_SHOW_COMPANIES_SELECT:
      return {
        ...state,
        showCompaniesSelect: action.showCompaniesSelect,
      };
    case actionTypes.SET_SHOW_USER_MENU:
      return {
        ...state,
        showUserMenu: action.showUserMenu,
      };
    case actionTypes.SET_SHOW_EDIT_TEAM_MENU:
      return {
        ...state,
        showEditTeamMenu: action.showEditTeamMenu,
      };
    case actionTypes.SET_SHOW_EDIT_TEAM_FAVORITE_MENU:
      return {
        ...state,
        showEditTeamFavoriteMenu: action.showEditTeamFavoriteMenu,
      };
    case actionTypes.SET_SELECTED_EDIT_TEAM:
      return {
        ...state,
        selectedEditTeam: action.selectedEditTeam,
      };
    case actionTypes.SET_SELECTED_EDIT_TEAM_FAVORITE:
      return {
        ...state,
        selectedEditTeamFavorite: action.selectedEditTeamFavorite,
      };
    case actionTypes.SET_IS_DELAY_SHOW_ACTIVITY_MENU:
      return {
        ...state,
        isDelayShowActivityMenu: action.isDelayShowActivityMenu,
      };
    case actionTypes.SET_IS_FAVORITE_LIST_EMPTY:
      return {
        ...state,
        isFavoriteListEmpty: action.isFavoriteListEmpty,
      };
    case actionTypes.SET_IS_DELAY_SHOW_NOTIF_MENU:
      return {
        ...state,
        isDelayShowNotifMenu: action.isDelayShowNotifMenu,
      };
    case actionTypes.SET_IS_DELAY_SHOW_COMPANIES_MENU:
      return {
        ...state,
        isDelayShowCompaniesMenu: action.isDelayShowCompaniesMenu,
      };
    case actionTypes.SET_IS_DELAY_SHOW_USER_MENU:
      return {
        ...state,
        isDelayShowUserMenu: action.isDelayShowUserMenu,
      };
    case actionTypes.SET_IS_DELAY_SHOW_EDIT_TEAM_MENU:
      return {
        ...state,
        isDelayShowEditTeamMenu: action.isDelayShowEditTeamMenu,
      };
    case actionTypes.SET_IS_DELAY_SHOW_EDIT_TEAM_FAVORITE_MENU:
      return {
        ...state,
        isDelayShowEditTeamFavoriteMenu: action.isDelayShowEditTeamFavoriteMenu,
      };
    case actionTypes.SET_IS_OPEN_TEAM_FROM_FAVORITE:
      return {
        ...state,
        isOpenTeamFromFavorite: action.isOpenTeamFromFavorite,
      };
    case actionTypes.SET_SHOULD_CHECK_TEAMS_COLOR:
      return {
        ...state,
        shouldCheckTeamsColor: action.shouldCheckTeamsColor,
      };
    case actionTypes.SET_EDIT_CARD_ITEMS:
      return {
        ...state,
        editCardItems: {
          card: action.card,
          list: action.list,
        },
      };
    default:
      //
      return state;
  }
};

export default Reducer;
